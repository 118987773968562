import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import { Gallery as PhotoSwipe } from 'react-photoswipe-gallery'

import { Image, Props as ImageProps } from './Image'

export interface Props {
  className?: string
  images?: ImageProps[]
  title?: string
}

export const Category = memo(function Category({
  className,
  images,
  title,
}: Props) {
  if (!images) {
    return null
  }

  return (
    <Container className={className}>
      {title ? <Title>{title}</Title> : null}
      <PhotoSwipe
        options={{
          barsSize: {
            top: 0,
            bottom: 0,
          },
          closeElClasses: '',
          closeOnScroll: false,
          closeOnVerticalDrag: false,
          pinchToClose: false,
          fullscreenEl: false,
          zoomEl: false,
          shareEl: false,
          preloaderEl: false,
          mouseUsed: true,
          tapToToggleControls: false,
          history: false,
          showHideOpacity: true,
          maxSpreadZoom: 1,
        }}
      >
        <Wrapper dial={2} row wrap>
          {images.map((item, index) => (
            <Image key={index} {...item} />
          ))}
        </Wrapper>
      </PhotoSwipe>
    </Container>
  )
})

const Container = styled.div`
  display: none;
  margin-top: 7.5rem;
  &.visible {
    display: block;
  }

  @media (max-width: 767px) {
    margin-top: 5.25rem;
  }
`

const Wrapper = styled(FlexBox)`
  max-width: 73.625rem;
  margin: 3.75rem auto 0;
  padding: 0 1.5rem;
`

const Title = styled.h3`
  display: flex;
  align-items: center;
  flex-direction: row;
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.625rem;
  line-height: 3.375rem;
  margin-bottom: 1.5rem;
  &:before,
  &:after {
    content: '';
    flex: 1 1;
    background: ${({ theme }) => theme.colors.variants.neutralLight1};
    height: 2px;
  }
  &:before {
    margin-right: 1.5rem;
  }
  &:after {
    margin-left: 1.5rem;
  }
`
