import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'

import styled from '@emotion/styled'
import { FadeInOutAnimation } from 'app/components/Common/FadeInOutAnimation'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo, useEffect, useRef, useState } from 'react'

import { Category, Props as CategoryProps } from './Category'
import { Filter, Props as FilterProps } from './Filter'

export interface Props {
  filters?: FilterProps[]
  languageCode: string
  categories?: CategoryProps[]
}

export const GalleryCategories = memo(function GalleryCategories({
  filters,
  languageCode,
  categories,
}: Props) {
  if (!categories) {
    return null
  }

  const containerRef = useRef<HTMLElement>(null)
  const [scroll, setScroll] = useState(false)
  const [filteredCategory, setFilteredCategory] = useState('all')

  useEffect(() => {
    const containerOffsetTop = containerRef.current?.offsetTop || 0

    const onScroll = () => {
      setScroll(window.scrollY > containerOffsetTop - 68)
    }

    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  return (
    <Container ref={containerRef}>
      {filters ? (
        <Filters className={scroll ? 'fixed' : ''} dial={2} row wrap>
          <Filter
            active={filteredCategory === 'all'}
            category="all"
            label={useVocabularyData('all', languageCode)}
            onClick={() => setFilteredCategory('all')}
          />
          {filters.map((item, index) => {
            const category = item.category
              ? item.category.replace(/[^a-zA-Z ]/g, '').toLocaleLowerCase()
              : undefined

            return (
              <Filter
                active={filteredCategory === category}
                key={index}
                {...item}
                onClick={(e) => {
                  setFilteredCategory(e.target.getAttribute('data-category'))
                }}
              />
            )
          })}
        </Filters>
      ) : null}

      {categories.map((item, index) => {
        const category = item.title
          ? item.title.replace(/[^a-zA-Z ]/g, '').toLocaleLowerCase()
          : undefined

        return (
          <Category
            className={
              filteredCategory === category || filteredCategory === 'all'
                ? 'visible'
                : undefined
            }
            key={index}
            {...item}
          />
        )
      })}
    </Container>
  )
})

const Container = styled.section`
  margin-bottom: 8.9375rem;
  padding-top: 1.75rem;
  position: relative;

  @media (max-width: 767px) {
    margin-bottom: 5.25rem;
  }

  /* OVERRIDE PHOTOSWIPE STYLE */
  .pswp__bg {
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
  }
  .pswp__img--placeholder--blank {
    background: none;
  }
  .pswp--touch {
    .pswp__button--arrow--left,
    .pswp__button--arrow--right {
      visibility: visible;
    }
  }
  .pswp__caption {
    display: flex;
    min-height: 0;
    background: none;
    .pswp__caption__center {
      max-width: none;
      background: ${({ theme }) => theme.colors.variants.neutralLight4};
      color: ${({ theme }) => theme.colors.variants.neutralDark3};
      font-family: ${({ theme }) => theme.fontFamily.paragraph};
      font-size: 0.8125rem;
      padding: 0.375rem 1.5rem;
      text-align: center;
    }
  }
  .pswp__top-bar {
    height: auto;
    background: none;
  }
  .pswp__button {
    width: 40px;
    height: 40px;
    background-color: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 1;
    &.pswp__button--arrow--left,
    &.pswp__button--arrow--right {
      margin-top: 0;
      transform: translateY(-50%);
      &:before {
        background-color: transparent;
        width: 40px;
        height: 40px;
        top: 0;
        left: 0;
      }
    }
    &.pswp__button--arrow--left {
      &:before {
        background-position: -135px -40px;
      }
    }
    &.pswp__button--arrow--right {
      &:before {
        background-position: -90px -40px;
      }
    }
  }
  .pswp__button--close {
    background-position-y: -47px;
  }
  .pswp__counter {
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    color: ${({ theme }) => theme.colors.variants.neutralLight4};
    font-family: ${({ theme }) => theme.fontFamily.paragraph};
    font-size: 0.8125rem;
    opacity: 1;
  }

  @media (-webkit-min-device-pixel-ratio: 1.1),
    (-webkit-min-device-pixel-ratio: 1.09375),
    (min-resolution: 1.1dppx),
    (min-resolution: 105dpi) {
    .pswp--svg .pswp__button--close:before,
    .pswp--svg .pswp__button--arrow--left:before,
    .pswp--svg .pswp__button--arrow--right:before {
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQgAAABYCAQAAACjBqE3AAAB6klEQVR4Ae3bsWpUQRTG8YkkanwCa7GzVotsI/gEgk9h4Vu4ySLYmMYgbJrc3lrwZbJwC0FMt4j7F6Y4oIZrsXtgxvx/1c0ufEX4cnbmLCmSJEmSJEmSJEmSJP3XCBPvbJU+8doWmDFwyZpLBmYlNJebz0KwzykwsuSYJSNwykEJreV2BaBMaLIQZ2xYcFgqDlmw4ayE/FwL0dDk4Qh4W37DAjgqIT+3HRbigjH+iikVdxgZStgyN0Su2sXIeTwTT+esdpcbIlfNAuZ/TxresG4zV8kYWSZNiKUTokMMSWeIwTNEn4fK2TW3gRNgVkJLuVksROA9G+bEvoATNlBCa7nZXEwdxEZxzpKRKFh+bsv8LmPFmhX1OwfIz81jIRJQ5eeqG9B+riRJkiRJkiRJkiRJkiRJkiRJUkvA/8RQoEpKlJWINFkJ62AlrEP/mNBibnv2yz/A3t7Uq3LcpoxP8COjC1T5vxoAD5VdoEqdDrd5QuW1swtUSaueh3zkiuBiqgtA2OlkeMcP/uDqugsJdbjHF65VdPMKwS0+WQc/MgKvrIOHysB9vgPwk8+85hmPbnQdvHZyDMAFD7L3EOpgMcVdvnHFS0/vlatrXvCVx0U9gt3fxvnA0/hB4nmRJEmSJEmSJEmSJGmHfgFLaDPoMu5xWwAAAABJRU5ErkJggg==) !important;
    }
  }
`

const Filters = styled(FlexBox)`
  width: 100%;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  border-top: 1px solid transparent;
  padding: 0 1.5rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  &.fixed {
    border-color: ${({ theme }) => theme.colors.variants.neutralLight1};
    position: fixed;
    top: 4.25rem;
  }

  @media (max-width: 767px) {
    &.fixed {
      position: absolute;
      top: 0;
    }
  }
`
